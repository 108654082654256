import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  VENDOR_POLICIES_FEATURE_KEY,
  VendorPoliciesState,
} from './vendor-policies.reducer';
import { Product } from '@chassis/shared/models';
import { getVendorPolicyProductKey } from '../models/policy.model';

export const selectPoliciesState = createFeatureSelector<VendorPoliciesState>(
  VENDOR_POLICIES_FEATURE_KEY,
);

export const selectProductPolicyState = (product: Product | undefined) =>
  createSelector(selectPoliciesState, (state) =>
    product ? state[getVendorPolicyProductKey(product)] : null,
  );

export const selectProductPolicyLoading = (product: Product | undefined) =>
  createSelector(selectProductPolicyState(product), (state) =>
    state ? state.loading : false,
  );

export const selectProductPolicyLoaded = (product: Product | undefined) =>
  createSelector(selectProductPolicyState(product), (state) =>
    state ? state.loaded : false,
  );

export const selectProductPolicyError = (product: Product | undefined) =>
  createSelector(selectProductPolicyState(product), (state) =>
    state ? state.error : null,
  );

export const selectProductPolicyHash = (product: Product | undefined) =>
  createSelector(selectProductPolicyState(product), (state) =>
    state ? state.policies : null,
  );
