import { createReducer, on } from '@ngrx/store';
import { VendorPolicyActions } from './vendor-policies.actions';
import {
  getVendorPolicyProductKey,
  VendorPolicyHash,
} from '../models/policy.model';
import { LoadableEntityError } from '@chassis/shared/models';

export const VENDOR_POLICIES_FEATURE_KEY = 'vendorPolicies';

export interface VendorProductPolicyState {
  loading: boolean;
  loaded: boolean;
  error: LoadableEntityError;
  policies: VendorPolicyHash | null;
}

export interface VendorPoliciesState {
  [key: string]: VendorProductPolicyState;
}

export interface VendorPoliciesPartialState {
  readonly [VENDOR_POLICIES_FEATURE_KEY]: VendorPoliciesState;
}

export const initialPartPolicyState: VendorProductPolicyState = {
  loading: false,
  loaded: false,
  error: null,
  policies: null,
};

export const initialState: VendorPoliciesState = {};

export const reducer = createReducer(
  initialState,
  on(VendorPolicyActions.load, (state, { product }) => {
    const productKey = getVendorPolicyProductKey(product);
    const partState = { ...initialPartPolicyState, ...state[productKey] };
    return {
      ...state,
      [productKey]: {
        ...partState,
        loading: true,
        loaded: false,
        error: null,
        policies: null,
      },
    };
  }),
  on(VendorPolicyActions.loadSuccess, (state, { product, vendorPolicies }) => {
    const productKey = getVendorPolicyProductKey(product);
    const partState = { ...initialPartPolicyState, ...state[productKey] };
    return {
      ...state,
      [productKey]: {
        ...partState,
        loading: false,
        loaded: true,
        policies: vendorPolicies,
      },
    };
  }),
  on(VendorPolicyActions.loadFailure, (state, { product, error }) => {
    const productKey = getVendorPolicyProductKey(product);
    const partState = { ...initialPartPolicyState, ...state[productKey] };
    return {
      ...state,
      [productKey]: {
        ...partState,
        loading: false,
        loaded: true,
        error,
      },
    };
  }),
);
