import { createActionGroup, props } from '@ngrx/store';
import { VendorPolicyHash } from '../models/policy.model';
import { Product } from '@chassis/shared/models';

const actionRoot = 'Vendor Policies';

export const VendorPolicyActions = createActionGroup({
  source: actionRoot,
  events: {
    Load: props<{ product: Product }>(),
    LoadSuccess: props<{
      product: Product;
      vendorPolicies: VendorPolicyHash;
    }>(),
    LoadFailure: props<{ product: Product; error: string }>(),
  },
});
