import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { VendorPolicyActions } from './vendor-policies.actions';
import { catchError, map, of, switchMap } from 'rxjs';
import { VendorPolicyService } from '../services/vendor-policy.service';

@Injectable()
export class VendorPoliciesEffects {
  readonly service = inject(VendorPolicyService);
  readonly actions$ = inject(Actions);

  loadVendorPolicies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VendorPolicyActions.load),
      switchMap(({ product }) => {
        return this.service.getVendorPolicies(product).pipe(
          map((vendorPolicies) =>
            VendorPolicyActions.loadSuccess({ product, vendorPolicies }),
          ),
          catchError((error) =>
            of(VendorPolicyActions.loadFailure({ product, error })),
          ),
        );
      }),
    ),
  );
}
