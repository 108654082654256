import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT, SharedEnvironment } from '@chassis/shared/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { getPartType, VendorPolicyHash } from '../models/policy.model';
import { Product } from '@chassis/shared/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VendorPolicyService {
  readonly env: SharedEnvironment = inject(ENVIRONMENT);
  readonly httpClient = inject(HttpClient);

  getVendorPolicies(product: Product): Observable<VendorPolicyHash> {
    const { condition, hollander_number } = product;
    const partType = getPartType(hollander_number as string);
    const params = new HttpParams({
      fromObject: {
        condition: condition,
        part_type: partType,
      },
    });

    const url = `${this.env.BASEURL_V4}/vendors/${product.vendor_id}/vendor_policies/by_part`;

    return this.httpClient
      .get<{ data: VendorPolicyHash }>(url, { params })
      .pipe(map(({ data }) => data));
  }
}
